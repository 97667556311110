import { useGetEventResponseDetailMutation, useUpdateEventResponseMutation } from '@Redux/services/EventResponse';
import { EventResponseDetail } from '@Redux/services/EventResponse/types';
import { Accessor, getErrorsAssociatedWithFields, getMutableForAccessor, parseError, parseWarning } from '@Utilities';
import { isUndefined } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    EventStatus,
    EVENT_RESPONSE_CANCELLED,
    EVENT_RESPONSE_CANCELLED_AND_CLOSED,
    EVENT_RESPONSE_CLOSED,
    EVENT_RESPONSE_CONFIRMED,
    EVENT_RESPONSE_CONFIRMED_AND_CLOSED,
    EVENT_RESPONSE_DRAFT,
    EVENT_RESPONSE_PENDING,
} from 'src/constants';
import useAutoSave from './useAutoSave';
import { useForms } from './useForms';
import { useToast } from './useToast';
import { Validator } from '@Pages/EventResponses/validator';
import { PartialDeep } from 'type-fest';

type IProps = {
    id: string;
    nextStageSuccess?: () => void;
    onCloseDrawer?: () => void;
};

export const useEventResponse = (props: IProps) => {
    const { t } = useTranslation();
    const [saveValidationError, setSaveValidationError] = useState(false);
    const toast = useToast();
    const [showCancelAlert, setShowCancelAlert] = useState(false);
    const [showResetAlert, setShowResetAlert] = useState(false);
    const [updateEventResponse, { isLoading: isSaving }] = useUpdateEventResponseMutation();
    const [alertProps, setAlertProps] = useState({
        title: '',
        message: '',
        submitLabel: '',
        cancelButtonLabel: '',
    });

    const getCommonProps = (accessorPath: Accessor[]) => {
        const accesor = accessorPath.join('.');

        const item = eventRequiredFields[accesor];
        const hasStatus = !isUndefined(item) && item.editableStatus?.indexOf(eventResponse?.status) > -1;
        const isLocked =
            (isUndefined(item) ? true : !hasStatus) || eventResponse?.event?.status !== EventStatus.Published;
        const isRequired = item && item.required && item.validateSave;
        return {
            isLocked,
            isRequired,
        };
    };
    const {
        formData: eventResponse,
        errors,
        manageFieldPropFactory,
        selectFieldPropFactory,
        setErrors,
        textAreaPropFactory,
        textFieldPropFactory,
        typeaheadPropFactory,
        switchFieldPropFactory,
        setFormData: setEventResponse,
        setIsDirty,
        dirtyData,
        setDirtyData,
        dirtyAccessors,
        setDirtyAccessor,
        isDirty,
    } = useForms<EventResponseDetail>(undefined, getCommonProps);

    const isClosedOrCanceled = useMemo(
        () =>
            [
                EVENT_RESPONSE_CANCELLED,
                EVENT_RESPONSE_CANCELLED_AND_CLOSED,
                EVENT_RESPONSE_CLOSED,
                EVENT_RESPONSE_CONFIRMED_AND_CLOSED,
            ].includes(eventResponse?.status),
        [eventResponse]
    );

    const eventRequiredFields = useMemo(() => {
        if (!eventResponse) return;
        const newEventRequiredFields = {};
        const validatons = Validator(eventResponse, t, false);
        validatons.map((validaton) => {
            newEventRequiredFields[validaton.accessorPath.join('.')] = validaton;
        });
        return newEventRequiredFields;
    }, [eventResponse]);

    const containerRef = useRef<HTMLDivElement>(null);
    const [getEventResponseDetail, { isLoading }] = useGetEventResponseDetailMutation();

    useEffect(() => {
        if (dirtyAccessors && Object.keys(dirtyAccessors).length > 0) {
            validate(true);
        }
    }, [dirtyAccessors]);

    const validate = (isAutoSave: boolean) => {
        const nextErrors: Record<string, string> = {};
        const validationRules = Validator(eventResponse, t, isAutoSave);
        const newErrors = { ...errors };
        const dirtyAccessorsString = {};
        if (isAutoSave) {
            for (const dirtyAccessor of dirtyAccessors) {
                dirtyAccessorsString[dirtyAccessor.join('.')] = true;
            }
        }

        for (const rule of validationRules) {
            const { accessorPath, validator, message, required, editableStatus, dependantPath } = rule;
            if (
                isAutoSave &&
                !(
                    dirtyAccessorsString[accessorPath.join('.')] ||
                    (dependantPath && dirtyAccessorsString[dependantPath.join('.')])
                )
            ) {
                continue;
            }
            const finalMutable = getMutableForAccessor(eventResponse, accessorPath);
            const finalProperty = accessorPath[accessorPath.length - 1];

            if (
                (required || isAutoSave) &&
                editableStatus?.indexOf(eventResponse?.status) > -1 &&
                !validator(finalMutable[finalProperty])
            ) {
                nextErrors[accessorPath.join('.')] = message;
            } else {
                if (newErrors[accessorPath.join('.')]) {
                    delete newErrors[accessorPath.join('.')];
                }
            }
        }
        setErrors({ ...newErrors, ...nextErrors });
        if (Object.keys(nextErrors).length) {
            !isAutoSave &&
                toast({
                    status: 'error',
                    description: t('EventDetails.toastErrorDescription'),
                });
            if (isAutoSave) {
                setSaveValidationError(true);
            }
            return false;
        }
        setSaveValidationError(false);
        return true;
    };

    const handleFormChange = async (data: EventResponseDetail, hasChanges?: boolean) => {
        if (saveValidationError || Object.keys(data).length === 0 || isClosedOrCanceled || !hasChanges) return;

        await updateEventResponse({
            eventResponseId: eventResponse?.eventResponseId,
            detail: {
                acceptingGuardianId: eventResponse?.detail.acceptingGuardianId,
                guardianNotFoundInList: eventResponse?.detail.guardianNotFoundInList,
                administrationComment: eventResponse?.detail.administrationComment,
                questionsAnswered: eventResponse?.detail.questionsAnswered,
            },
            payment: {
                methodOfPayment: eventResponse?.payment.methodOfPayment,
                amountPaid: eventResponse?.payment.amountPaid,
            },
            cancellation: {
                refundPercentage: eventResponse?.cancellation.refundPercentage,
                reason: eventResponse?.cancellation.reason,
            },
            subsidy: {
                subsidyAmount: eventResponse?.subsidy.subsidyAmount,
                maxSubsidyAmount: eventResponse?.subsidy.maxSubsidyAmount,
                confirmedOn: eventResponse?.subsidy.confirmedOn,
            },
        })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                if (eventResponse?.status === EVENT_RESPONSE_PENDING) {
                    props.nextStageSuccess && props.nextStageSuccess();
                }
                setEventResponse({
                    ...response.data,
                });
                setDirtyData({});
                setIsDirty(false);
                setHasPendingChange(false);
                setDirtyAccessor([]);
            })
            .catch((error) => {
                const nextErrors: Record<string, string> = {};
                for (const dirtyAccessor of dirtyAccessors) {
                    nextErrors[dirtyAccessor.join('.')] = t('EventDetails.fieldNotSaved');
                }
                setErrors(getErrorsAssociatedWithFields(error.data.errors, Object.keys(eventRequiredFields)));
                parseError(toast, error);
            });
    };

    const { hasPendingChange, handleManualSave, setHasPendingChange } = useAutoSave<PartialDeep<EventResponseDetail>>({
        data: dirtyData,
        onChange: handleFormChange,
    });

    const getEventResponseData = async () => {
        if (!props.id) return;
        await getEventResponseDetail({ eventResponseId: props.id })
            .unwrap()
            .then((response) => {
                parseWarning(toast, response);
                setEventResponse({
                    ...response.data,
                });
            })
            .catch((error) => {
                parseError(toast, error);
            });
    };

    useEffect(() => {
        getEventResponseData();
    }, [props.id]);

    const responseStatus = useMemo(
        () =>
            eventResponse?.status === EVENT_RESPONSE_CANCELLED
                ? eventResponse?.statusPriorToCancellation
                : eventResponse?.status,
        [eventResponse]
    );

    const steps = useMemo(() => {
        return [
            {
                active: EVENT_RESPONSE_PENDING === responseStatus,
                header: t('EventResponseDetail.step1'),
                subHeader: t('EventResponseDetail.step1Subheader'),
            },
            {
                active: EVENT_RESPONSE_DRAFT === responseStatus,
                header: t('EventResponseDetail.step2'),
                subHeader: t('EventResponseDetail.step2Subheader'),
            },
            {
                active: EVENT_RESPONSE_CONFIRMED === responseStatus,
                header: t('EventResponseDetail.step3'),
                subHeader: t('EventResponseDetail.step3Subheader'),
            },
        ];
    }, [responseStatus]);

    const getNextStatus = (status: number) => {
        switch (status) {
            case EVENT_RESPONSE_PENDING:
                return EVENT_RESPONSE_DRAFT;
            case EVENT_RESPONSE_DRAFT:
                return EVENT_RESPONSE_CONFIRMED;
            default:
                return 0;
        }
    };

    const handleActionClick = (isCancel: boolean) => () => {
        if (isCancel) {
            setShowCancelAlert(true);
            setAlertProps({
                title: t('EventResponses.cancelTitle'),
                message: t('EventResponses.cancelMessage'),
                submitLabel: t('EventResponses.cancelSubmitLabel'),
                cancelButtonLabel: t('EventResponses.cancelButtonLabel'),
            });
        } else {
            setShowResetAlert(true);
            setAlertProps({
                title: t('EventResponses.resetTitle'),
                message: t('EventResponses.resetMessage'),
                submitLabel: t('EventResponses.resetSubmitLabel'),
                cancelButtonLabel: t('EventResponses.resetButtonLabel'),
            });
        }
    };

    const handleStatusUpdate = async (isCancel: boolean) => {
        if (isCancel && !validate(false)) {
            setShowCancelAlert(false);
            return;
        }
        if ((!showCancelAlert && !showResetAlert) || isSaving) return;
        let status = EVENT_RESPONSE_PENDING;
        if (isCancel) {
            status = EVENT_RESPONSE_CANCELLED;
        }
        handleCloseAlert();
        const data = {};
        if (isCancel) {
            data['cancellation'] = {
                reason: eventResponse.cancellation.reason,
                refundPercentage: eventResponse.cancellation.refundPercentage,
            };
        }
        await updateEventResponse({
            eventResponseId: eventResponse.eventResponseId,
            status,
            ...data,
        })
            .unwrap()
            .then(async (response) => {
                setEventResponse({
                    ...response.data,
                });
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    toast({
                        status: 'success',
                        description: showCancelAlert
                            ? t('EventResponses.toastCancelSuccess')
                            : t('EventResponses.toastResetSuccess'),
                    });
                }
                props.nextStageSuccess && props.nextStageSuccess();
                if (isCancel) {
                    props.onCloseDrawer && props.onCloseDrawer();
                }

                setDirtyData({});
                setIsDirty(false);
                setHasPendingChange(false);
                setDirtyAccessor([]);
                setErrors({});
            })
            .catch((error) => {
                setErrors(getErrorsAssociatedWithFields(error.data.errors, Object.keys(eventRequiredFields)));
                parseError(toast, error);
            });
    };

    const handleNext = useCallback(async () => {
        if (!validate(false) || isSaving) return;

        await updateEventResponse({
            eventResponseId: eventResponse.eventResponseId,
            detail: {
                ...eventResponse.detail,
            },
            payment: eventResponse.payment,
            cancellation: eventResponse.cancellation,
            subsidy: eventResponse.subsidy,
            status: getNextStatus(eventResponse.status),
        })
            .unwrap()
            .then(async (response) => {
                setEventResponse(response.data);
                let toastDescription = t('EventResponses.toastCompleteSuccess');
                if (response.data.status === EVENT_RESPONSE_DRAFT) {
                    toastDescription = t('EventResponses.toastDraftSuccess');
                }
                if (response?.warningResult?.showAlert) {
                    parseWarning(toast, response);
                } else {
                    props.nextStageSuccess && props.nextStageSuccess();
                    if (props.onCloseDrawer && getNextStatus(eventResponse.status) === EVENT_RESPONSE_CONFIRMED) {
                        props.onCloseDrawer();
                    }
                    toast({
                        status: 'success',
                        description: toastDescription,
                    });
                }
            })
            .catch((error) => {
                setErrors(getErrorsAssociatedWithFields(error.data.errors, Object.keys(eventRequiredFields)));
                parseError(toast, error);
            });
    }, [eventResponse, setErrors, showCancelAlert, showResetAlert]);

    const handleCloseAlert = () => {
        setShowCancelAlert(false);
        setShowResetAlert(false);
    };

    const stepsProps = {
        eventResponse,
        errors,
        manageFieldPropFactory,
        selectFieldPropFactory,
        setErrors,
        setEventResponse,
        textAreaPropFactory,
        textFieldPropFactory,
        typeaheadPropFactory,
        switchFieldPropFactory,
        setFormData: setEventResponse,
        setIsDirty,
        dirtyData,
        setDirtyData,
        dirtyAccessors,
        setDirtyAccessor,
        isDirty,
    };

    const isEventPublished = useMemo(() => eventResponse?.event.status === EventStatus.Published, [eventResponse]);
    const isResponseDraftOrPending = useMemo(
        () => eventResponse?.status === EVENT_RESPONSE_DRAFT || eventResponse?.status === EVENT_RESPONSE_PENDING,
        [eventResponse]
    );
    return {
        handleActionClick,
        handleCloseAlert,
        handleFormChange,
        handleManualSave,
        handleNext,
        handleStatusUpdate,
        getNextStatus,
        isLoading,
        eventResponse,
        steps,
        stepsProps,
        showCancelAlert,
        showResetAlert,
        alertProps,
        containerRef,
        isClosedOrCanceled,
        hasPendingChange,
        isSaving,
        isEventPublished,
        isResponseDraftOrPending,
        isDirty,
        setIsDirty,
    };
};
